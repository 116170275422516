import React from 'react';
import { language } from '../assets/lang';
import { functions, functionsEU } from './firebase/firebase';
import Lockersale from '../assets/images/lockersale.svg';
import Eventsafe from '../assets/images/partners/eventsafe.svg';
import LockerCompany from '../assets/images/partners/lockercompany.svg';
import Elockers from '../assets/images/partners/elockers.svg';
import Olssen from '../assets/images/partners/olssen.svg';
import Lock from '../assets/images/icons/lock.svg';
import IconBG from '../assets/images/icons/icon_bg.svg';
import Lockerwait from '../assets/images/lockerwait.svg';
import Warning from '../assets/images/icons/pp_warning.svg';

class Payment extends React.Component {
    render() {	
        return ( 
        <div style={{height: '100%', width: '100%'}}>
            <div onClick={() => this.props.changeEventState('')} style={{marginLeft: 5, marginTop: 10, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
            </div>

            {!this.state.loaded  ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', paddingTop: 100}}>	
                <img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 60, height: 60, marginBottom: 30}}/>		
            </div>
            :
            <div>
                {this.state.lockerList.length === 0 ?
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <img src={Lockersale} style={{width: 320, height: 200, marginTop: 10}} />
                    <p style={{fontSize: 21, marginTop: 20, color: '#22293b', fontFamily: 'Kentledge-Bold', textAlign: 'center'}}>{language.lockers.charAt(0).toUpperCase() + language.lockers.slice(1)}</p>	
                    <p style={{fontSize: 14, marginTop: 10, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.nolockersadded}</p>	
                    <div onClick={() => this.props.changeEventState('payment', false, 'lockers')} style={{display: 'flex', marginTop: 40, height: 42, width: 170, alignItems: 'center', justifyContent: 'center', backgroundColor: '#b32e5d', borderRadius: 20}}>
                        <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge'}}>{language.buylocker}</p>
                    </div>
                </div>
                :
                <div>
                    <div style={{paddingLeft: 25, paddingRight: 25, paddingBottom: 20, flexDirection: 'row'}}>
                        <p style={{fontSize: 17, color: '#22293b', fontFamily: 'Kentledge-Bold', }}>{language.mylockers}</p>
                        {this.state.lockerList.map((kluisje, index) => (
                        <div key={index} style={{position: 'relative'}}>
                            <div className='lockerContainer'>
                                <div style={{backgroundColor: '#fff', borderRadius: 12, width: '100%', paddingBottom: 30, marginTop: -1}}>
                                    <div style={{display: 'flex', width: '100%', backgroundColor: 'rgba(0,0,0,0.03)', height: 130, alignItems: 'center', justifyContent: 'center', borderTopLeftRadius: 12, borderTopRightRadius: 12}}>
                                        {kluisje.supplier === 'eventsafe' ?
                                        <img src={Eventsafe} style={{width: 300, height: 60}} />
                                        :null}
                                        {kluisje.supplier === 'lockercompany' ?
                                        <img src={LockerCompany} style={{width: 100, height: 100}} />
                                        :null}
                                        {kluisje.supplier === 'elockers' ?
                                        <img src={Elockers} style={{width: 200, height: 45}} />
                                        :null}
                                        {kluisje.supplier === 'olssen' ?
                                        <img src={Olssen} style={{width: 200, height: 50}} />
                                        :null}
                                        {kluisje.supplier !== 'olssen' && kluisje.supplier !== 'lockercompany' && kluisje.supplier !== 'eventsafe' && kluisje.supplier !== 'elockers' ?
                                        <div style={{justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
                                            <img src={Lock} style={{width: 40, height: 40}} />
                                            <p style={{fontSize: 18, marginTop: 5, textAlign: 'center', color: '#22293b', fontFamily: 'Kentledge'}}>{language.locker}</p>
                                        </div>
                                        :null}
                                    </div>
                                    <div style={{paddingLeft: 30, paddingRight: 30, }}>
                                        <p style={{fontSize: 12, marginTop: 30, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', }}>Info</p>
                                        {kluisje.old ?
                                        <p style={{fontSize: 14, color: '#b32e5d', fontFamily: 'Kentledge' }}>{language.lockerexpired}</p>
                                        :
                                        <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge' }}>{kluisje.desc ? kluisje.desc : '-'}</p>
                                        }
                                        {kluisje.keynius ?
                                        <div style={{width: '100%'}}>
                                            <p style={{fontSize: 12, marginTop: 20, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', }}>{language.validon}</p>
                                            <p style={{fontSize: 14, marginTop: 1, color: '#22293b', fontFamily: 'Kentledge' }}>{this.formatDate(kluisje.geldig)}</p>
                                            <p style={{fontSize: 12, marginTop: 20, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', }}>{language.number}</p>
                                            {kluisje.fetched ?
                                            <p style={{fontSize: 24, marginTop: 3, marginBottom: -7, color: '#22293b', fontFamily: 'Kentledge-Bold'}}>{kluisje.num.trim()}</p>
                                            :
                                            <div style={{display: 'flex', marginTop: 7, flexDirection: 'row'}}>{[...Array(6).keys()].map((item, index)=>(<div key={index} style={{width: 10, height: 10, borderRadius: 10, marginRight: 3, borderWidth: 1.5, borderStyle: 'solid', borderColor: '#22293b'}}></div>))}</div>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginTop: 20}}>
                                                <div style={{width: '60%'}}>
                                                    <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', }}>{language.validfrom}</p>
                                                    <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge' }}>{this.formatDate(kluisje.geldig)}</p>
                                                </div>
                                                <div>
                                                    <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', }}>{language.validtill}</p>
                                                    <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge' }}>{this.formatDate(kluisje.end)}</p>
                                                </div>
                                            </div>
                                            <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginTop: 20}}>
                                                <div style={{width: '60%'}}>
                                                    <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', }}>{language.number}</p>
                                                    {kluisje.fetched ?
                                                    <p style={{fontSize: 24, marginTop: 3, color: '#22293b', fontFamily: 'Kentledge-Bold'}}>{kluisje.num.trim()}</p>
                                                    :
                                                    <div style={{marginTop: 7, flexDirection: 'row', display: 'flex'}}>{[...Array(6).keys()].map((item, index)=>(<div key={index} style={{width: 11, height: 11, borderRadius: 10, marginRight: 3, borderWidth: 1.5, borderStyle: 'solid', borderColor: '#22293b'}}></div>))}</div>
                                                    }
                                                </div>
                                                <div>
                                                    <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', }}>{language.pincode}</p>
                                                    {kluisje.fetched && !kluisje.codehidden ?
                                                    <p style={{fontSize: 24, marginTop: 3, color: '#b32e5d', fontFamily: 'Kentledge-Bold'}}>{kluisje.code.trim()}</p>
                                                    :
                                                    <div style={{marginTop: 7, flexDirection: 'row', display: 'flex'}}>{[...Array(4).keys()].map((item, index)=>(<div key={index} style={{width: 11, height: 11, borderRadius: 10, marginRight: 3, borderWidth: 1.5, borderStyle: 'solid', borderColor: '#b32e5d'}}></div>))}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {kluisje.keynius ?
                                        <div>
                                            <div className='titleDivider' style={{marginTop: 35, marginBottom: 30}}></div>
                                            {kluisje.num ?
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10}}>
                                                <div onClick={() => this.openKeyniusLocker(kluisje.id)} style={{display: 'flex', flexDirection: 'row', width: 200, borderRadius: 20, height: 36, justifyContent: 'center', paddingHorizontal: 15, alignItems: 'center', backgroundColor: '#4cc15f'}}>
                                                    <p style={{fontSize: 12, color: '#fff', fontFamily: 'Kentledge', textAlign: 'center'}}>{kluisje.num ? language.openlocker : language.getlockernum}</p>
                                                </div>
                                                <div onClick={() => this.shareLocker()} style={{display: 'flex', flexDirection: 'row', width: 200, borderRadius: 20, height: 36, marginTop: 15, justifyContent: 'center', paddingHorizontal: 15, alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.06)'}}>
                                                    <p style={{fontSize: 12, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.sharelocker}</p>
                                                </div>
                                            </div>
                                            :
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10}}>
                                                <div onClick={() => {if(!kluisje.fetched)this.fetchLocker(kluisje)}} style={{display: 'flex', flexDirection: 'row', width: 200, borderRadius: 20, height: 36, justifyContent: 'center', paddingHorizontal: 15, alignItems: 'center', backgroundColor: '#b32e5d'}}>
                                                    <p style={{fontSize: 12, color: '#fff', fontFamily: 'Kentledge', textAlign: 'center'}}>{kluisje.num ? language.openlocker : language.getlockernum}</p>
                                                </div>
                                                <p style={{fontSize: 12, marginTop: 25, color: 'rgba(0,0,0,0.6)', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.lockersharetxt}</p>
                                            </div>
                                            }
                                        </div>
                                        :											
                                        <div>
                                            <div className='titleDivider' style={{marginTop: 35, marginBottom: 30}}></div>
                                            {kluisje.fetched ?
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10}}>
                                                <div onClick={() => this.hidepincode(index)} style={{display: 'flex', flexDirection: 'row', width: 220, borderRadius: 20, height: 36, justifyContent: 'center', paddingHorizontal: 15, alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.06)'}}>
                                                    <p style={{fontSize: 12, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{kluisje.codehidden ? language.showpincode : language.hidepincode}</p>
                                                </div>
                                                <div onClick={() => this.shareLocker(kluisje.num, kluisje.code)} style={{display: 'flex', flexDirection: 'row', width: 220, marginTop: 15, borderRadius: 20, height: 36, justifyContent: 'center', paddingHorizontal: 15, alignItems: 'center', backgroundColor: '#4cc15f'}}>
                                                    <p style={{fontSize: 12, color: '#fff', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.sharelocker}</p>
                                                </div>
                                            </div>
                                            :
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 5}}>
                                                <div onClick={() => {if(!kluisje.fetched)this.fetchLocker(kluisje)}} style={{display: 'flex', flexDirection: 'row', width: 220, borderRadius: 20, height: 36, justifyContent: 'center', paddingHorizontal: 15, alignItems: 'center', backgroundColor: '#b32e5d'}}>
                                                    <p style={{fontSize: 12, color: '#fff', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.getpincode}</p>
                                                </div>
                                                <p style={{fontSize: 12, marginTop: 30, color: 'rgba(0,0,0,0.6)', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.lockersharetxt}</p>
                                            </div>
                                            }
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}	
                    </div>
                    <div style={{paddingLeft: 25, paddingRight: 25}}>
                        <div className='newLockerContainer'>
                            <img src={Lockersale} style={{width: 170, height: 160}} />
                            <p style={{fontSize: 13, marginTop: 12, color: '#fff', fontFamily: 'Kentledge', textAlign: 'center', paddingHorizontal: 20}}>{language.lockerbuytxt}</p>	
                            <div onClick={() => this.props.changeEventState('payment', false, 'lockers')} style={{marginTop: 25, height: 38, paddingHorizontal: 15, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', borderRadius: 20}}>
                                <p style={{fontSize: 13, color: '#b32e5d', fontFamily: 'Kentledge'}}>{language.buylocker}</p>
                            </div>
                        </div>
                        <p style={{fontSize: 10, marginTop: 35, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge'}}>{language.lockerconditions}</p>
                    </div>
                </div>
                }
            </div>
            }

			{this.state.fetchModal ?
            <div className="modal-overlay" onClick={()=>this.setState({fetchModal: false})}>
                <div className="modal-content" >
                    <div style={{width: '100%', height: this.state.fetchState === 0 ? 200 : (this.state.fetchState === 1 ? 400 : 290), alignItems: 'center'}}>
                        {this.state.fetchState === 0 ?
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 50, height: 50}}/>
                            <p style={{fontSize: 14, marginTop: 35, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.gettinglockerpatient}</p>							
                        </div>
                        :null}
                        {this.state.fetchState === 1 ?
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center'}}>
                             <img src={Lockerwait} style={{width: 200, height: 200, marginTop: 10}} />
                            <p style={{fontSize: 20, marginTop: 15, color: '#22293b', fontFamily: 'Kentledge-Bold', textAlign: 'center'}}>{language.waitamoment}</p>	
                            <p style={{fontSize: 14, paddingHorizontal: 50, marginTop: 10, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.nolockersaddeddate}</p>	
                            <div onClick={() => this.setState({fetchModal: false})} style={{display: 'flex', marginTop: 40, marginBottom: 30, height: 42, width: 170, alignItems: 'center', justifyContent: 'center', backgroundColor: '#b32e5d', borderRadius: 20}}>
                                <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge'}}>{language.closewindow}</p>
                            </div>
                        </div>	
                        :null}
                        {this.state.fetchState === 2 ?
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 10, overflow: 'hidden'}}>				
                            <img src={Warning} style={{width: 185, height: 100}} />
                            <div style={{paddingLeft: 30, paddingRight: 30, alignItems: 'center', justifyContent: 'center', }}>
                                <p style={{fontSize: 18, marginTop: 20, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge-Bold'}}>Oops...</p>
                                <p style={{fontSize: 14, marginTop: 15, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge'}}>{language.somethingwronggetlockers}</p>
                            </div>	
                            <div onClick={() => this.setState({fetchModal: false})} style={{display: 'flex', marginTop: 25, backgroundColor: '#b32e5d', borderRadius: 20, display: 'flex', flexDirection: 'row', width: 150, height: 42, alignItems: 'center', justifyContent: 'center'}}>
                                <p style={{fontSize: 12, color: '#fff', fontFamily: 'Kentledge', textAlign: 'center',}}>{language.closewindow}</p>
                            </div>							
                        </div>
                        :null}
                    </div>
                </div>
            </div>
			:null}

			{this.state.openLockerModal ?
            <div className="modal-overlay" onClick={()=>this.setState({openLockerModal: false})}>
                <div className="modal-content" >
                    {this.state.openLockerState === 0 ?
                    <div style={{width: '100%', height: 150, marginTop: 35, alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                        <img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 50, height: 50}}/>
                        <p style={{fontSize: 14, marginTop: 35, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.openinglocker}</p>							
                    </div>
                    :null}
                    {this.state.openLockerState === 1 ?
                    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                       <img src={Lockersale} style={{width: 200, height: 200, marginTop: 20}} />
                        <p style={{paddingLeft: 15, paddingRight: 15, fontSize: 20, marginTop: 15, color: '#22293b', fontFamily: 'Kentledge-Bold', textAlign: 'center'}}>{language.lockeropen}</p>	
                        <p style={{paddingLeft: 15, paddingRight: 15, fontSize: 14, marginTop: 10, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.lockerdooropen}</p>	
                        <div onClick={() => this.setState({openLockerModal: false})} style={{display: 'flex', marginTop: 20, marginBottom: 30, height: 42, width: 170, alignItems: 'center', justifyContent: 'center', backgroundColor: '#b32e5d', borderRadius: 20}}>
                            <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge'}}>{language.closewindow}</p>
                        </div>
                    </div>	
                    :null}
                    {this.state.openLockerState === 2 ?
                    <div style={{display: 'flex', flexDirection: 'column', paddingBottom: 25, width: '100%', alignItems: 'center', borderRadius: 10, overflow: 'hidden'}}>				
                        <img src={Warning} style={{marginTop: 20, width: 180, height: 100}} />
                        <div style={{marginTop: 20, paddingLeft: 15, paddingRight: 15, alignItems: 'center', justifyContent: 'center', }}>
                            <p style={{fontSize: 18, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge-Bold'}}>Oops...</p>
                            <p style={{fontSize: 14, marginTop: 3, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge'}}>{language.somethingwronggetlockers}</p>
                        </div>	
                        <div onClick={() => this.setState({openLockerModal: false})} style={{display: 'flex', marginTop: 30, height: 42, width: 170, alignItems: 'center', justifyContent: 'center', backgroundColor: '#b32e5d', borderRadius: 20}}>
                            <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge'}}>{language.closewindow}</p>
                        </div>							
                    </div>
                    :null}
                    {this.state.openLockerState === 3 ?
                    <div style={{display: 'flex', flexDirection: 'column', paddingBottom: 25, width: '100%', alignItems: 'center', borderRadius: 10, overflow: 'hidden'}}>				
                        <img src={Warning} style={{marginTop: 20, width: 180, height: 100}} />
                        <div style={{marginTop: 20, paddingLeft: 15, paddingRight: 15, alignItems: 'center', justifyContent: 'center', }}>
                            <p style={{fontSize: 18, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge-Bold'}}>{language.pleasewait}</p>
                            <p style={{fontSize: 14, marginTop: 3, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge'}}>{language.lockernotstarted}</p>
                        </div>	
                        <div onClick={() => this.setState({openLockerModal: false})} style={{display: 'flex', marginTop: 30, height: 42, width: 170, alignItems: 'center', justifyContent: 'center', backgroundColor: '#b32e5d', borderRadius: 20}}>
                            <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge'}}>{language.closewindow}</p>
                        </div>							
                    </div>
                    :null}
                    {this.state.openLockerState === 4 ?
                    <div style={{display: 'flex', flexDirection: 'column', paddingBottom: 25, width: '100%', alignItems: 'center', borderRadius: 10, overflow: 'hidden'}}>				
                        <img src={Warning} style={{marginTop: 20, width: 180, height: 100}} />
                        <div style={{marginTop: 20, paddingLeft: 15, paddingRight: 15, alignItems: 'center', justifyContent: 'center', }}>
                            <p style={{fontSize: 18, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge-Bold'}}>{language.lockerexpired}</p>
                            <p style={{fontSize: 14, marginTop: 3, color: '#22293b', textAlign: 'center', fontFamily: 'Kentledge'}}>{language.lockercannotopen}</p>
                        </div>	
                        <div onClick={() => this.setState({openLockerModal: false})} style={{display: 'flex', marginTop: 30, height: 42, width: 170, alignItems: 'center', justifyContent: 'center', backgroundColor: '#b32e5d', borderRadius: 20}}>
                            <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge'}}>{language.closewindow}</p>
                        </div>							
                    </div>
                    :null}
                </div>
			</div>
			:null}


		</div>            
        )
    }

    state = {loaded: false, lockerList: [], fetchModal: false, fetchState: 0, openLockerModal: false, openLockerState: 0}

    componentDidMount(){this.getLockerList()}

    getLockerList(){
        var getCardLockers = functionsEU.httpsCallable('getCardLockers');
        getCardLockers({cardID: this.props.cardID, eid: this.props.eventData.eventid}).then(result => { 
            console.log(result);
            if(result.data.state === 1){this.setState({lockerList: result.data.lockers, loaded: true, fetchModal: false})}
            else{this.setState({error: true, loaded: true, fetchModal: false})}
        }).catch(e => {console.log(e); this.setState({error: true, loaded: true, fetchModal: false})});
    }
    
    fetchLocker(kluisje){
        if(!this.fetchingLocker){
            this.fetchingLocker = true;
            this.setState({fetchState: 0, fetchModal: true});	
            var lockerData = {eid: this.props.eventData.eventid, uid: this.props.userData.id, lockerId: kluisje.id};
            var getLocker = functions.httpsCallable('getLocker');
            if(this.checkLC(kluisje.id2)){lockerData = Object.assign(lockerData,this.checkLC(kluisje.id2))}
            getLocker(lockerData).then(result => {
                if(result.data.status === "succes"){
                    setTimeout(() => this.getLockerList(), 1000);	
                }
                if(result.data.status === "empty"){
                    this.setState({fetchState: 1});	
                }
                if(result.data.status === "error"){
                    this.setState({fetchState: 2});	
                }
                this.fetchingLocker = false;
            }).catch(() => {this.fetchingLocker = false});
        }
    }
    
    hidepincode(ind){
        var lockerList = this.state.lockerList;
        lockerList[ind].codehidden = !lockerList[ind].codehidden;
        this.setState({lockerList: lockerList});
    }
    
    openKeyniusLocker(id){
        this.setState({openLockerModal: true, openLockerState: 0});
        var keyniusOpen = functions.httpsCallable('keyniusOpen');
        keyniusOpen({lid: id, eid: this.props.eventData.eventid, uid: this.props.userData.id, oid: this.props.eventData.oid}).then(result => {
            this.setState({openLockerState: result.data.state});
        }).catch(() => this.setState({openLockerState: 2}));
    }
    
    shareLocker(num,code){
        if(!num && !code){alert('Cannot share this locker'); return null}
        var url = 'https://wa.me/?text=I would like to share my locker. My locker number is ' + num + ' and the code is ' + code;
        window.open(url, '_blank');
    }
    
    formatDate(dag){
        if(dag){
            var date = new Date(0);
            date.setUTCSeconds(parseFloat(dag));
            return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2)  + '-' + date.getFullYear();
        }
        return 'N.v.t.';
    }
    
    checkLC(id){
        var lockerCompanyData = false;
        this.props.eventData.kluizen.forEach(period => {
            var currLocker = period.lockers.find(locker => locker.id === id);
            if(currLocker && currLocker.lockerCompany && currLocker.lockerCompany.connect){
                lockerCompanyData = {lockerCompany: true, period: currLocker.lockerCompany.period, size: currLocker.lockerCompany.size, assignAt: currLocker.lockerCompany.assignAt};
            }
        })
        return lockerCompanyData;
    }

    
}
    
export default Payment;
import React from 'react';
import { language } from '../assets/lang';
import { functionsEU } from '../components/firebase/firebase';

class Payment extends React.Component {
    render() {	
        return ( 	
        <div style={{}}>				
            {this.state.functionState === 0 ?
            <div onClick={() => this.createPayment()} style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: 50, marginBottom: 15}}>
                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 42, width: 180, borderRadius: 20}}>
                    <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge-bold'}}>{language.paying}</p>
                </div>
            </div>
            :null}

            {this.state.functionState === 1 ?
            <div style={{display: 'flex', flexDirection: 'column', height: 150, alignItems: 'center', justifyContent: 'center'}}>
                <img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 35, height: 35, marginTop: 40}}/>
                <p style={{width: 250, marginTop: 25, fontSize: 13, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{this.state.status}</p>
            </div>
            :null}

            {this.state.functionState === 2 ?
            <div style={{marginTop: 25, display: 'flex', flexDirection: 'column', height: 150, alignItems: 'center', justifyContent: 'center'}}>
                <p style={{width: 200, marginTop: 10, fontSize: 13, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.insufficientlockers}</p>
                <div onClick={() => this.props.changeEventState('')} style={{marginTop: 25, display: 'flex', flexDirection: 'row', backgroundColor: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 36, width: 140, borderRadius: 20}}>
                    <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>{language.goback}</p>
                </div>
            </div>
            :null}

            {this.state.functionState === 3 ?
            <div style={{marginTop: 25, display: 'flex', flexDirection: 'column', height: 150, alignItems: 'center', justifyContent: 'center'}}>
                <p style={{width: 250, marginTop: 10, fontSize: 13, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.sthwrongcreatepaym}</p>
                <div onClick={() => this.props.changeEventState('')} style={{marginTop: 25, display: 'flex', flexDirection: 'row', backgroundColor: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 36, width: 140, borderRadius: 20}}>
                    <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>{language.goback}</p>
                </div>
            </div>
            :null}

            {this.state.functionState === 4 ?
            <div style={{marginTop: 25, display: 'flex', flexDirection: 'column', height: 150, alignItems: 'center', justifyContent: 'center'}}>
                <p style={{width: 250, marginTop: 10, fontSize: 13, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.nopaymentsatm}</p>
                <div onClick={() => this.props.changeEventState('')} style={{marginTop: 25, display: 'flex', flexDirection: 'row', backgroundColor: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 36, width: 140, borderRadius: 20}}>
                    <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>{language.goback}</p>
                </div>
            </div>
            :null}
        </div>
        )
    }

    state = {functionState: 0}

    createPayment(){
        this.setState({functionState: 1, status: language.creatingpayment});
        const longwait = setTimeout(() => {this.setState({status: language.takinglittlelonger})}, 4000);
        const longerwait = setTimeout(() => {this.setState({status: language.possibleinetprob})}, 8000);
        const longestwait = setTimeout(() => {if(this.props.eventData.paymentMethods.find(m => m.id === 'pp1' && m.status || m.id === 'pp2' && m.status))this.setState({status: language.inetprobtopup})}, 13000);
        var topupCard = functionsEU.httpsCallable('topupCard');
        topupCard({orderObj: JSON.stringify(this.props.afrekenObj), description: this.createDescription(), eid: this.props.eventData.eventid, oid: this.props.eventData.oid, cardID: this.props.cardID}).then(result => {
            clearTimeout(longwait); clearTimeout(longerwait); clearTimeout(longestwait);
            if(result.data.text == "Niet voldoende beschikbaar"){
                this.setState({functionState: 2, status: ''});
            }
            else if(result.data.text == "Er is iets mis gegaan"){
                this.setState({functionState: 3, status: ''});
            }
            else if(result.data.text == "Geen betalingen mogelijk"){
                this.setState({functionState: 4, status: ''});
            }
            else{
                window.location = result.data;
            }
        });
    }
    
    createDescription(){
        var orderData = this.props.afrekenObj;
        var descTokens = '';
        var descLockers = '';
        if(orderData['tokens'].aantal > 0){
            descTokens = descTokens + orderData['tokens'].aantal;
            descTokens = descTokens.concat(orderData['tokens'].aantal === 1 ? ' ' + language.token : ' ' + language.tokens);
            if(orderData.locker.aantal > 0){descLockers = ' ' + language.and + ' '}
        }
        if(orderData.locker.aantal > 0){descLockers = descLockers + '1 ' + language.locker}
        return '' + descTokens + descLockers + ' ' + language.for + ' ' + this.props.eventData.naam;
    }  
    
}
    
    export default Payment;
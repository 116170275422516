import React from 'react';
import { language } from '../assets/lang';
import { functionsEU } from '../components/firebase/firebase';
import { BarcodeScanner } from 'react-barcode-scanner'
import 'react-barcode-scanner/polyfill'

class Payment extends React.Component {
    render() {	
        return ( 	
        <div style={{width: '100%', height: '100%', paddingBottom: 30}}>	

            <div style={{marginTop: 10, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => this.props.changeEventState('')}>
                <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
            </div>			

            {this.state.shareState === 'input' ? 
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <div style={{marginBottom: 20}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p style={{fontSize: 20, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.howmanytokenuwant}</p>
                        <p style={{fontSize: 20, marginTop: 2, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.wanttoshare}?</p>
                        <input type='number' inputMode='numeric' pattern="[0-9]*" value={this.state.tokens === 0 ? '' : this.state.tokens.toString()} ref={(input) => {this.tokenInput = input}} keyboardType='number-pad' selectionColor={'#b32e5d'} style={{marginTop: 30, color: this.state.tokenError !== '' ? '#b32e5d' : '#22293b', height: 50, justifyContent: 'center', paddingLeft: 20, borderRadius: 7, backgroundColor: 'rgba(0,0,0,0.04)', fontFamily: 'Kentledge', fontSize: 15, borderWidth: 0, borderColor:'rgba(0,0,0,0.1)'}} onChange={e => this.addTokens(e.target.value)} placeholderTextColor='rgba(0,0,0,0.2)' placeholder={language.atokencostseu} />
                    </div>
                </div>
                <div style={{width: '100%', marginTop: 25, height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
                <div style={{marginTop: 25}}>
                    {this.state.error !== '' ?
                    <p style={{fontSize: 14, color: '#b32e5d', marginTop: 20, fontFamily: 'Kentledge'}}>{this.state.error}</p>
                    :null}
                    <p style={{fontSize: 12, PaddingLeft: 20, paddingRight: 20, marginTop: 20, color: 'rgba(0,0,0,0.4)', fontFamily: 'Kentledge'}}>{language.tokensharediscl}</p>	
                </div>
                <div style={{width: '100%', marginTop: 25, marginBottom: 12, height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
                {this.state.tokens > 0 ?
                <div onClick={() => this.setState({shareState: 'scan'})} style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: 35}}>
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', backgroundColor: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 42, width: 180, borderRadius: 20}}>
                        <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge-bold'}}>{language.continue}</p>
                    </div>
                </div>
                :
                <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: 35}}>
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.06)', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 42, width: 180, borderRadius: 20}}>
                        <p style={{fontSize: 14, color: 'rgba(0,0,0,0.1)', fontFamily: 'Kentledge-bold'}}>{language.continue}</p>
                    </div>
                </div>
                }
            </div>
            :null}

            {this.state.shareState === 'scan' ? 
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <p style={{fontSize: 20, marginTop: 20, marginBottom: 35, color: '#22293b', fontFamily: 'Kentledge-bold', }}>{language.scanpastoshare} {this.state.tokens} {this.state.tokens === 1 ? language.token : language.tokens}</p>
                <div style={{marginTop: 35, position: 'relative', height: 260}}>
                    <BarcodeScanner onCapture={qrvalue => this.processQR(qrvalue)} />
                    <div style={{position: 'absolute', top: 0, left: 0, height: 260, width: '100%'}}>
                        <div style={{position: 'absolute', top: 50, left: '25%',}}>
                            <div style={{width: 20, height: 4, backgroundColor: '#fff',}}></div>
                            <div style={{width: 4, height: 20, backgroundColor: '#fff',}}></div>
                        </div>
                        <div style={{position: 'absolute', top: 200, left: '25%', marginTop: -20}}>
                            <div style={{width: 4, height: 20, backgroundColor: '#fff',}}></div>
                            <div style={{width: 20, height: 4, backgroundColor: '#fff',}}></div>
                        </div>
                        <div style={{position: 'absolute', top: 50, left: '75%',}}>
                            <div style={{width: 20, height: 4, backgroundColor: '#fff', marginLeft: -20}}></div>
                            <div style={{width: 4, height: 20, backgroundColor: '#fff', marginLeft: -4}}></div>
                        </div>
                        <div style={{position: 'absolute', top: 200, left: '75%', marginTop: -20}}>
                            <div style={{width: 4, height: 20, backgroundColor: '#fff', marginLeft: -4}}></div>
                            <div style={{width: 20, height: 4, backgroundColor: '#fff', marginLeft: -20}}></div>
                        </div>
                    </div>	
                </div>
            </div>
            :null}

            {this.state.shareState === 'process' ? 
            <div style={{display: 'flex', flexDirection: 'column', height: 250, alignItems: 'center', justifyContent: 'center'}}>
                <img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 45, height: 45, marginTop: 40}}/>
                <p style={{width: 250, marginTop: 35, fontSize: 15, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.processingtokenshare}</p>
            </div>
            :null}

            {this.state.shareState === 'succes' ? 
            <div style={{display: 'flex', flexDirection: 'column', height: 250, alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: 'flex', borderColor: '#669e3f', marginTop: 45, borderWidth: 1, borderRadius: 55, borderStyle: 'solid', height: 110, width: 110, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <i class="fa fa-check" style={{color: '#669e3f', fontSize: 40}}></i>
                </div>
                <p style={{width: 250, marginTop: 35, fontSize: 15, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.tokensharesucces}</p>
            </div>
            :null}

            {this.state.shareState === 'sameuser' ? 
            <div style={{display: 'flex', flexDirection: 'column', height: 250, alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: 'flex', borderColor: '#b32e5d', marginTop: 45, borderWidth: 1, borderRadius: 55, borderStyle: 'solid', height: 110, width: 110, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <i class="fa fa-exclamation" style={{color: '#b32e5d', fontSize: 40}}></i>
                </div>
                <p style={{width: 250, marginTop: 35, fontSize: 15, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.sharesameuser}</p>
            </div>
            :null}

            {this.state.shareState === 'error' ? 
            <div style={{display: 'flex', flexDirection: 'column', height: 250, alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: 'flex', borderColor: '#b32e5d', marginTop: 45, borderWidth: 1, borderRadius: 55, borderStyle: 'solid', height: 110, width: 110, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <i class="fa fa-exclamation" style={{color: '#b32e5d', fontSize: 40}}></i>
                </div>
                <p style={{width: 250, marginTop: 35, fontSize: 15, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.tokenshareerror}</p>
            </div>
            :null}

        </div>
        )
    }

    state = {shareState: 'input', tokens: 0, error: ''}
    
    componentDidMount(){
        this.tokenInput.focus()
    }
    
    addTokens(num){
        num = num ? parseFloat(num.replace(/[.,-]/g,'')) : 0;
        if(this.props.userData.tokens >= num){
            this.setState({tokens: num, error: ''});
        }
        else{
            if(this.props.userData.tokens === 0){
                this.setState({error: language.invalidbalance});
            }
            else if(this.props.userData.tokens === 0.5){
                this.setState({error: language.mintokenshare});
            }
            else{
                this.setState({error: language.canmax + ' ' + (this.props.userData.tokens ? this.props.userData.tokens : 0) + ' ' + (this.props.userData.tokens === 1 ? language.token : language.tokens)});
            }
        }
    }

    processQR(qrvalue){
        var data = qrvalue && qrvalue.rawValue ? qrvalue.rawValue : undefined;
        if(data && data.includes('card?id=')){
            this.shareTokens(data.split('card?id=')[1].split('&evt=')[0]);
        }
        else if(data && data.includes('card.partypay.nl/?id=')){
            this.shareTokens(data.split('card.partypay.nl/?id=')[1].split('&evt=')[0])
        }
    }
    
    shareTokens(cardID){
        this.setState({shareState: 'process'},()=>{
            var shareCardTokens = functionsEU.httpsCallable('shareCardTokens');
            shareCardTokens({tokens: this.state.tokens, cardID: cardID, uid: this.props.userData.id, eid: this.props.eventData.eventid, oid: this.props.eventData.oid}).then(result => { 
                if(result.data.state === 1){this.setState({shareState: 'succes'})}
                else if(result.data.state === 3){this.setState({shareState: 'sameuser'})}
                else{this.setState({shareState: 'error'})}
            }).catch(e => {console.log(e); this.setState({shareState: 'error'})});
        })
    }
    
    setError(){
        this.setState({shareState: 'input',tokens: 0,error: language.somethingwrong})
    }
    
}
    
export default Payment;
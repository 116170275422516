import React from 'react';
import { language } from '../assets/lang';
import { functionsEU } from './firebase/firebase';

class Payment extends React.Component {
    render() {	
        return ( 	
        <div style={{width: '100%', height: '100%', paddingBottom: 30}}>	

            <div onClick={() => this.props.changeEventState('')} style={{marginTop: 10, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
            </div>			

			{!this.state.loaded ?
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100, paddingBottom: 100}}>	
				<img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 60, height: 60, marginBottom: 30}}/>		
			</div>
			:
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <div style={{marginBottom: 20}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p style={{fontSize: 20, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.howmanytokenuwant}</p>
                        <p style={{fontSize: 20, marginTop: 2, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.wanttorefund}?</p>
                        <input type='number' disabled={this.state.sendState === 3} inputMode='numeric' pattern="[0-9]*" value={this.state.tokenInput === 0 ? '' : this.state.tokenInput.toString()} ref={(input) => {this.tokenInput = input}} keyboardType='number-pad' selectionColor={'#b32e5d'} style={{marginTop: 30, color: '#22293b', height: 50, justifyContent: 'center', paddingLeft: 20, borderRadius: 7, backgroundColor: 'rgba(0,0,0,0.04)', fontFamily: 'Kentledge', fontSize: 15, borderWidth: 0, borderColor:'rgba(0,0,0,0.1)'}} onChange={(e) => this.increaseTokens(e.target.value ? parseFloat(e.target.value.replace(/[.,-]/g,'')) : 0)} placeholderTextColor='rgba(0,0,0,0.2)' placeholder={language.atokencostseu} />
                    </div>
                </div>
                <div style={{width: '100%', marginTop: 25, marginBottom: 25, height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
                {this.state.error !== '' ? 
                <p style={{fontSize: 14, paddingHorizontal: 20, color: '#b32e5d', fontFamily: 'Kentledge', textAlign: 'center'}}>{this.state.error}</p>
                :
                <div>
                    {this.state.sendState === 0 ?
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end', marginBottom: 15, marginTop: 10}}>
                        <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge'}}>{language.returntokval}</p>
                        <p style={{fontSize: 24, color: '#22293b', fontFamily: 'Kentledge-bold', textAlign: 'right'}}>{'\u20AC'} {(this.state.tokenInput * parseFloat(this.state.laatsteTransactie.orderData.tokens.kosten / this.state.laatsteTransactie.orderData.tokens.aantal)).toFixed(2).replace(".", ",")}</p>
                        {this.props.eventData.pay.serviceFee.chargeUser ?
                        <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', textAlign: 'right'}}>Excl. {'\u20AC'} {this.state.retourfee.toFixed(2).replace(".", ",")} {language.processcost}</p>
                        :
                        <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', textAlign: 'right'}}>{language.noservicecost}</p>
                        }
                    </div>
                    :null}
                    {this.state.sendState === 1 ?
                    <div style={{display: 'flex', flexDirection: 'column', height: 150, alignItems: 'center', justifyContent: 'center'}}>									
                       	<img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 35, height: 35}}/>		
                        <p style={{width: 250, marginTop: 25, fontSize: 14, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.createreturnpayment}</p>
                    </div>	
                    :null}
                    {this.state.sendState === 2 ?
                    <div style={{width: '100%', paddingHorizontal: 20, paddingTop: 10, paddingBottom: 10}}>
                        <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.refunderror}</p>									
                    </div>
                    :null}
                    {this.state.sendState === 3 ?
                    <div style={{width: '100%', paddingHorizontal: 20, paddingTop: 10, paddingBottom: 10}}>
                        <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.returnapprec7days} {'\u20AC'} {((this.state.tokenInput * parseFloat(this.state.laatsteTransactie.orderData.tokens.kosten / this.state.laatsteTransactie.orderData.tokens.aantal)) - this.state.retourfee).toFixed(2).replace(".", ",")} {language.returnedtoacc}.</p>									
                    </div>
                    :null}
                </div>
                }
                <div style={{width: '100%', marginTop: 25, marginBottom: 25, height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 15, marginTop: 35}}>
                    {this.state.laatsteTransactie && this.state.tokenInput > 0 && this.state.error === '' && this.state.sendState === 0 ?
                    <div onClick={() => this.verwerkRetourVerzoek()} style={{display: 'flex', backgroundColor: '#b32e5d', borderRadius: 30, width: 200, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:45}}>
                        <p style={{fontSize: 15, color: '#f1f2f4', fontFamily: 'Kentledge-bold', textAlign: 'center',}}>{language.refund}</p>
                    </div>
                    :
                    <div>
                        {this.state.sendState === 3 ?
                        <div onClick={() => this.props.changeEventState('', true)} style={{display: 'flex', backgroundColor: '#b32e5d', borderRadius: 30, width: 170, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:45}}>
                            <p style={{fontSize: 15, color: '#f1f2f4', fontFamily: 'Kentledge-bold', textAlign: 'center',}}>{language.goback}</p>
                        </div>
                        :
                        <div style={{display: 'flex', backgroundColor: 'rgba(0,0,0,0.06)', borderRadius: 30, width: 200, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:45}}>
                            <p style={{fontSize: 15, color: 'rgba(0,0,0,0.1)', fontFamily: 'Kentledge-bold', textAlign: 'center',}}>{language.refund}</p>
                        </div>
                        }
                     </div>
                    }
                </div>
            </div>
            }
        </div>
        )
    }

    state = {loaded: false, tokenInput: 0, error: '', sendState: 0, confirm: false, retourfee: 0}

    componentDidMount(){this.returnTransOphalen()}

    returnTransOphalen(){
        if(this.props.userData.returnID && this.props.userData.returnID !== ''){
            var getCardOders = functionsEU.httpsCallable('getCardOders');
            getCardOders({uid: this.props.userData.id, eid: this.props.eventData.eventid, returnID: this.props.userData.returnID}).then(result => { 
                if(result.data.state === 1){
                    var retourfee = this.props.eventData.paymentMethods.find(mtd => mtd.id === result.data.orderData.orderData.method).retourfee;
                    this.setState({loaded: true, laatsteTransactie: result.data.orderData, retourfee: retourfee},()=>{setTimeout(() => {this.tokenInput.focus()}, 1)})
                }
                else{this.setState({loaded: true, error: language.haventboughtokideal})}
            }).catch(e => {console.log(e); this.setState({error: true, loaded: true})});
        }
        else{this.setState({loaded: true, error: language.haventboughtokideal})}
    }

    increaseTokens(amount){
        if(this.state.laatsteTransactie){
            this.setState({error: '', tokenInput: amount},()=>{
                if(this.state.laatsteTransactie.refunded){
                    this.setState({error: language.alreadyreturned})
                }
                else if((amount) > this.state.laatsteTransactie.orderData.tokens.aantal){
                    this.setState({error: language.cantbemorelasttrans})
                }
                else if((amount) > this.props.userData.tokens){
                    if(this.props.userData.tokens <= 0.5){
                        this.setState({error: language.invalidbalance});
                    }
                    else{
                        this.setState({error: language.canmax + ' ' + (this.props.userData.tokens === 1 ? (this.props.userData.tokens + ' ' + language.token) : (this.props.userData.tokens + ' ' + language.tokens))})
                    }
                }
                else if(this.periodExpired() || this.dateExpired()){
                    this.setState({error: language.expiredreturnper})
                }
            })
        }
        else{
            this.setState({loaded: true, error: language.haventboughtokideal});
        }
    }
    
    periodExpired(){
        if(this.props.eventData.muntretour.verval === 'periode'){
            var today = new Date();
            var periode = this.props.eventData.muntretour.periode;
            if((this.state.laatsteTransactie.tijd + (periode * 86400)) < Math.round(today.getTime() / 1000)){return true}
        }
        return false;
    }
    
    dateExpired(){
        if(this.props.eventData.muntretour.verval === 'datum'){
            if(Math.floor(Date.now() / 1000) > this.props.eventData.muntretour.date){return true}
        }
        return false;
    }
    
    verwerkRetourVerzoek(){
        this.setState({error: '', sendState: 1})
        var retourVerzoek = functionsEU.httpsCallable('refundTransaction');
        retourVerzoek({tokens: this.state.tokenInput, transaction: this.props.userData.returnID, uid: this.props.userData.id, eid: this.props.eventData.eventid, oid: this.props.eventData.oid}).then(result => {
            if(result.data.text === 'verwerkt'){this.setState({sendState: 3})}
            else{this.setState({sendState: 2})}
        });
    }
    
}
    
export default Payment;
import React from 'react';
import { language } from '../assets/lang';
import { functionsEU } from '../components/firebase/firebase';
import Payment from './payment';
import Transactions from './transactions';
import Share from './share';
import Refund from './refund';
import Lockers from './lockers';
import CalendarDay from '../assets/images/icons/calendar-day.svg';
import Map from '../assets/images/icons/map.svg';
import ShareIcon from '../assets/images/icons/share2.svg';
import RefundIcon from '../assets/images/icons/refund.svg';
import WalletIcon from '../assets/images/icons/wallet2.svg';


class EventContainer extends React.Component {
    render() {	
        return ( 	
        <div style={{width: '100%', height: '100%', paddingBottom: 30}}>				

            <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', height: 210, overflow: 'hidden'}}>
                <img src={this.props.eventData.cover} style={{minWidth: '100%', objectFit: 'contain'}} /> 
                {!this.searchParams.has("evt") ? <div className='backbutton' onClick={() => this.props.getEvents()}><i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i></div> :null}
            </div>
 
            {this.state.eventState === '' ? 
            <div className='contentContainer'>

                <div className='titleContainer'>
                    <p style={{fontSize: 12, fontFamily: 'Kentledge', color: 'rgba(0,0,0,0.4)'}}>{this.props.eventData.type === 'locatie' ? language.eventlocation : language.event}</p>
                    <p style={{fontSize: 19, marginTop: -2, fontFamily: 'Kentledge-Bold', color: '#22293b'}}>{this.props.eventData.naam}</p>
                    <div className='titleDivider'></div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={CalendarDay} style={{width: 17, height: 17, opacity: 0.4, marginLeft: 1}} />
                        <p style={{fontSize: 13, fontFamily: 'Kentledge', marginLeft: 9, color: 'rgba(0,0,0,0.6)'}}>{this.formatDate(this.props.eventData.tijd, this.props.eventData.type, this.props.eventData.dagen)}</p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 5}}>
                        <img src={Map} style={{width: 18, height: 18, opacity: 0.4}} />
                        <p style={{fontSize: 13, fontFamily: 'Kentledge', marginLeft: 10, color: 'rgba(0,0,0,0.6)'}}>{this.props.eventData.locatie}</p>
                    </div>
                </div>
                
                <p style={{fontSize: 17, color: '#22293b', fontFamily: 'Kentledge-Bold', marginTop: 45, marginBottom: 15}}>{language.mybalance}</p>
                <div className='saldo' style={{backgroundColor: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d'}}>
                    <div style={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <p style={{fontSize: 30, color: '#f1f2f4', fontFamily: 'Kentledge-bold'}}>{this.state.userData.tokens ? this.state.userData.tokens : 0}</p>
                            <p style={{fontSize: 22, marginLeft: 7, color: 'rgba(255,255,255,0.8)', fontFamily: 'Kentledge', marginTop:1}}>{this.state.userData.tokens === 1 ? language.token : language.tokens}</p>
                        </div>
                        {this.state.userData.tokens < -0.5 ?
                        <p style={{fontSize: 12, marginTop: 7, color: 'rgba(255,255,255,0.55)', width: 200, fontFamily: 'Kentledge'}}>{language.negativebalancesoonpay}.</p>
                        :
                        <p style={{fontSize: 14, marginTop: 7, color: 'rgba(255,255,255,0.55)', fontFamily: 'Kentledge'}}>{this.state.updating === 'updating' ? language.updatingbalance :null}{this.state.updating === 'updated' ? language.balanceupdated :null}{this.state.updating === 'offline' ? language.noconnection :null}</p>
                        }
                    </div>
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <div onClick={() => this.setState({eventState: 'payment', afrekenState: 'tokens'})} style={{display: 'flex', flexDirection: 'row', backgroundColor: '#f1f2f4', paddingLeft: 17, paddingRight: 17, alignItems: 'center', justifyContent: 'space-between', height: 36, width: 182, borderRadius: 20}}>
                            <p style={{fontSize: 12.5, color: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d', fontFamily: 'Kentledge'}}>{language.topupbalance}</p>
                            <i class="fa fa-chevron-right" style={{color: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d'}}></i>
                        </div>
                    </div>
                    {this.props.eventData.muntretour.allowed ?
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25}}>
                        <div onClick={() => this.shareTokens()} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={ShareIcon} style={{width: 14, height: 14, opacity: 0.8}} />
                            <p style={{fontSize: 12, marginLeft: 6, color: 'rgba(255,255,255,0.85)', fontFamily: 'Kentledge'}}>{language.share}</p>
                        </div>
                        <div onClick={() => this.setState({eventState: 'refund'})} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={RefundIcon} style={{width: 14, height: 14, opacity: 0.8}} />
                            <p style={{fontSize: 12, marginLeft: 6, color: 'rgba(255,255,255,0.85)', fontFamily: 'Kentledge'}}>{language.refund}</p>
                        </div>
                        <div onClick={() => this.setState({eventState: 'transactions'})} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <img src={WalletIcon} style={{width: 14, height: 14, opacity: 0.8}} />
                        <p style={{fontSize: 12, marginLeft: 6, color: 'rgba(255,255,255,0.85)', fontFamily: 'Kentledge'}}>{language.expenses}</p>
                        </div>
                    </div>
                    :
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25}}>
                        <div onClick={() => this.shareTokens()} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={ShareIcon} style={{width: 16, height: 16, opacity: 0.8}} />
                            <p style={{fontSize: 12.5, marginLeft: 10, color: 'rgba(255,255,255,0.85)', fontFamily: 'Kentledge'}}>{language.sharetokens}</p>
                        </div>
                        <div onClick={() => this.setState({eventState: 'transactions'})} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={WalletIcon} style={{width: 16, height: 16, opacity: 0.8}} />
                            <p style={{fontSize: 12.5, marginLeft: 10, color: 'rgba(255,255,255,0.85)', fontFamily: 'Kentledge'}}>{language.spendings}</p>
                        </div>
                    </div>
                    }
                    <div style={{height: 15}}>	
                    </div>
                    <div onClick={() => this.getSaldo()} className={this.state.updating === "updating" ? "rotating" : ""} style={{position: 'absolute', height: 30, right: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', right: 17, top: 13, zIndex: 999, cursor: 'pointer'}}>
                        <i class="fa fa-arrows-rotate" style={{fontSize: 20, color: '#fff', opacity: 0.8}}></i>
                    </div>
                </div>

                <p style={{fontSize: 17, color: '#22293b', fontFamily: 'Kentledge-Bold', marginTop: 45, marginBottom: 20}}>{language.info}</p>
                <div>
                    {this.props.eventData.weblinks && this.props.eventData.weblinks.length > 0 ? this.props.eventData.weblinks.map((item, index) => (
                    <div className='btn_container' onClick={() => this.openWeblink(item.url)}>
                        <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'left'}} numberOfLines={1}>{item.text}</p>          
                        <i class="fa fa-chevron-right" style={{color: '#22293b', fontSize: 14}}></i>
                    </div>
                    )):
                    <div className='announcement_container' style={{height: 130, justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{opacity: 0.4, textAlign: 'center', fontSize: 15, fontFamily: 'Kentledge'}}>{language.noinfo}</p>
                    </div>
                    }
                </div>

                <p style={{fontSize: 17, color: '#22293b', fontFamily: 'Kentledge-Bold', marginTop: 45, marginBottom: 20}}>{language.announcements}</p>
                <div>
                    {this.props.eventData.announcements.map((item, index) => (
                        <div key={index} className='announcement_container'>						
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>							
                                <div style={{width: 50, height: 50, marginRight: 15, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 50}}>
                                    <img src={this.props.eventData.cover} style={{width: 180, height: 100}} /> 
                                </div>
                                <div>
                                    <p style={{fontSize: 16, color: '#22293b', fontFamily: 'Kentledge-Bold'}}>{item.naam}</p>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -2}}>
                                        <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge'}}>{language.postedon} {this.formattime(item.tijd)}</p>
                                    </div>
                                </div>
                            </div>							
                            <p style={{fontSize: 15, color: '#22293b', fontFamily: 'Kentledge'}}>{item.title}</p>
                            <p style={{fontSize: 12, marginTop: 15, color: '#22293b', fontFamily: 'Kentledge'}}>{item.text}</p>
                            {item.buttonUrl ?
                            <center>
                                <a href={item.buttonUrl} style={{display: 'flex', flexDirection: 'row', marginTop: 25, backgroundColor: this.props.eventData.webcolor ? this.props.eventData.webcolor : '#b32e5d', paddingLeft: 20, paddingRight: 20, width: 'fit-content', alignItems: 'center',  justifyContent: 'space-between', height: 36, borderRadius: 20}}>
                                    <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge', marginLeft: 5, marginRight: 25}}>{item.buttonText && item.buttonText !== '' ? item.buttonText : language.gotolink}</p>
                                    <i class="fa fa-chevron-right" style={{color: '#fff', fontSize: 14}}></i>
                                </a>
                            </center>
                            :null}
                        </div>
                    ))}	
                    {this.props.eventData.announcements.length === 0 ?
                    <div className='announcement_container' style={{height: 130, justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{opacity: 0.4, textAlign: 'center', fontSize: 15, fontFamily: 'Kentledge'}}>{language.noannouncements}</p>
                    </div>
                    :null}
                </div>

            </div>  
            :null}       

            {this.state.eventState === 'transactions' ? <Transactions changeEventState={this.changeEventState} userData={this.state.userData} eventData={this.props.eventData} /> :null}
            {this.state.eventState === 'payment' ? <Payment changeEventState={this.changeEventState} cardID={this.props.cardID} eventData={this.props.eventData} afrekenState={this.state.afrekenState} /> :null}	
            {this.state.eventState === 'share' ? <Share changeEventState={this.changeEventState} userData={this.state.userData} eventData={this.props.eventData} /> :null}	
            {this.state.eventState === 'refund' ? <Refund changeEventState={this.changeEventState} userData={this.state.userData} eventData={this.props.eventData} /> :null}	
            {this.state.eventState === 'lockers' ? <Lockers changeEventState={this.changeEventState} cardID={this.props.cardID} userData={this.state.userData} eventData={this.props.eventData} /> :null}	

        </div>
        )
    }

    state = {userData: '', updating: 'updated', eventState: '', afrekenState: ''}

    componentDidMount(){
        const url = new URL(window.location.href);
        if(url.searchParams.has("locker")){
            url.searchParams.delete("locker");
            window.history.replaceState({}, document.title, url);
            this.setState({eventState: 'lockers'});
        }
        this.getSaldo()
    }
    
    getSaldo(){
        this.setState({updating: 'updating'});
        var getCardBalance = functionsEU.httpsCallable('getCardBalance');
        getCardBalance({cardID: this.props.cardID, eid: this.props.eventData.eventid}).then(result => { 
            if(result.data.state === 1){this.setState({updating: 'updated', userData: result.data.data})}
            else{this.setState({updating: 'updated'})}
        }).catch(e => {console.log(e); this.setState({updating: 'offline'})});

    }

    openWeblink(url){
        if(url === '#lockers'){this.setState({eventState: 'lockers'})}
        else if(url === '#klantenservice'){window.open('https://wa.me/+31854011114?text=My account number is ' + this.props.cardID + '---' + this.state.userData.id + ' and my question is: ', '_blank')}
        else{window.open(url, '_blank')}
    }

    formatDate(tijd, type, dagen){
        if(type === 'event'){
            var date = new Date(0);
            date.setUTCSeconds(tijd);
            var maand = ' ' + language.months[date.getMonth()] + ' ';
            if(dagen && dagen > 1){
                var date2  = new Date(date);
                date2.setDate(date.getDate() + (dagen -1))
                if(date.getMonth() === date2.getMonth()){
                    maand = ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date.getMonth()] + ' ';
                }
                else{
                    maand = ' ' + language.months[date.getMonth()] + ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date2.getMonth()] + ' ';
                }
            }		
            return '' + date.getDate() + maand + date.getFullYear();
        }
        return language.eventlocation;
    }

    formattime(tijd){
        var datum = new Date(0);
        datum.setUTCSeconds(tijd);
        var minuten = datum.getMinutes();
        if(datum.getMinutes() < 10){minuten = '0' + datum.getMinutes()}
        var timestamp = datum.getDate() + ' ' + language.monthsshort[datum.getMonth()] + ' ' + language.at + ' ' + datum.getHours() + ':' + minuten;
        return timestamp;
    }

    openTimetable(){
        if(this.props.eventData.timetable && this.props.eventData.timetable.length > 0){
            window.location.href = this.props.eventData.timetable[0].img;
        }
        else{alert(language.timetableunavailable)}
    }

    openFestivalmap(){
         if(this.props.eventData.festivalmap && this.props.eventData.festivalmap !== ''){
            window.location.href = this.props.eventData.festivalmap;
         }
         else{alert(language.mapunavailable)}
    }

    openLockers(){
        alert(language.lockersunavailable)
    }

    openWhatsapp(){
        window.location.href = 'whatsapp://send?phone=+31854011114+&p=My account number is ' + this.props.cardID + '---' + this.state.userData.id + ' and my question is: ';
    }

    shareTokens(){
        if(this.props.eventData.preventShare){alert(language.nosharedesc)}
        else{this.setState({eventState: 'share'})}
    }

    changeEventState = (state, refresh, afrekenState) => {
        this.setState({eventState: state, afrekenState: afrekenState ? afrekenState : ''},()=>{
            window.scrollTo(0,0);
            if(refresh){this.getSaldo()}
        })
    }
    searchParams = new URL(window.location.href).searchParams;
  
    
    }
    
    export default EventContainer;